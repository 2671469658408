import React, { FC, useContext, useEffect } from "react";
import { Heading, Box, Container, Stack } from "@chakra-ui/react";
import { LayoutContext } from "../../components/layout";
import { graphql, PageProps, Link } from "gatsby";
import { UpdatesTemplateQuery } from "../../../graphql-types";
import Img, { FluidObject } from "gatsby-image";
import Masonry from "react-masonry-component";
import { BrochureFooter } from "../../components/brochure/footer";
import { Footer } from "../../components/footer";
import { motion } from "framer-motion";

export const query = graphql`
  query UpdatesTemplate {
    allWpPost(sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          title
          uri
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const UpdatesTemplate: FC<PageProps<UpdatesTemplateQuery>> = props => {
  const { data } = props;

  const { mapMachine } = useContext(LayoutContext);
  const [, mapSend] = mapMachine as NonNullable<typeof mapMachine>;

  useEffect(() => {
    mapSend("HIDE_MAP");
  }, [mapSend]);

  const itemGap = "0.75rem";

  return (
    <motion.div
      key={props.path}
      initial={{ opacity: 0, x: "100%" }}
      animate={{ opacity: 1, x: "0%" }}
      exit={{ opacity: 0, x: "-100%" }}
      transition={{ duration: 0.5 }}
    >
      <Box as="main" bgColor="forestGreen" pt={{ base: 32, md: 48 }}>
        <Container maxW="xl">
          <Stack spacing={{ base: 4, md: 16 }}>
            <Heading
              color="white"
              fontSize={{ base: "5xl", md: "7xl" }}
              textStyle="korolevCompressed"
            >
              Updates en nieuws
            </Heading>

            <Masonry
              style={{ marginLeft: `-${itemGap}`, marginRight: `-${itemGap}` }}
            >
              {data.allWpPost.edges.map(edge => (
                <Box
                  key={edge.node.id}
                  w={{ base: "100%", md: 1 / 2, lg: 1 / 3 }}
                  p={itemGap}
                >
                  <Link to={edge.node.uri}>
                    {edge.node.featuredImage?.node?.localFile && (
                      <Img
                        fluid={{
                          ...(edge.node.featuredImage?.node?.localFile
                            ?.childImageSharp?.fluid as FluidObject),
                          aspectRatio: 380 / 340,
                        }}
                      />
                    )}

                    <Box bgColor="darkFern" p={6}>
                      <Heading size="sm" fontWeight="normal" color="white">
                        {edge.node.title}
                      </Heading>
                    </Box>
                  </Link>
                </Box>
              ))}
            </Masonry>
          </Stack>
        </Container>
      </Box>

      <footer>
        <BrochureFooter />
        <Footer />
      </footer>
    </motion.div>
  );
};

export default UpdatesTemplate;
